<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="add-new-data-sidebar items-no-padding"
    spacer
    v-model="isSidebarActiveLocal"
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>
        {{
          Object.entries(this.data).length === 0 ? $t("AddNew") : $t("Update")
        }}
      </h4>
      <feather-icon
        icon="XIcon"
        @click.stop="isSidebarActiveLocal = false"
        class="cursor-pointer"
      ></feather-icon>
    </div>

    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar
      class="scroll-area--data-list-add-new"
      :settings="settings"
    >
      <h4>{{ $t("WithdrawalAndPayment") }}</h4>
      <vs-card title="icon">
        <div class="vx-row m-4">
          <label class="text-sm opacity-75">{{ $t("PaymentWayName") }}</label>
          <vs-input
            class="w-full"
            v-model="PaymentWayName"
            v-validate="'required'"
            name="PaymentWayName"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('PaymentWayName')"
            >{{ errors.first("PaymentWayName") }}</span
          >
        </div>
        <div class="vx-row m-4">
          <label class="text-sm opacity-75">{{ $t("OperationNumber") }}</label>
          <vs-input
            class="w-full"
            v-model="OperationNumber"
            v-validate="'required'"
            name="OperationNumber"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('OperationNumber')"
            >{{ errors.first("OperationNumber") }}</span
          >
        </div>
        <div class="vx-row m-4">
          <label class="text-sm opacity-75">{{ $t("OperationSide") }}</label>
          <vs-input
            class="w-full"
            v-model="OperationSide"
            v-validate="'required'"
            name="OperationSide"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('OperationSide')"
            >{{ errors.first("OperationSide") }}</span
          >
        </div>
        <div class="vx-row m-4">
          <label class="text-sm opacity-75">{{ $t("OperationStatus") }}</label>
          <vs-checkbox v-model="OperationStatus"> </vs-checkbox>
          <span
            class="text-danger text-sm"
            v-show="errors.has('OperationStatus')"
            >{{ errors.first("OperationStatus") }}</span
          >
        </div>
      </vs-card>
    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData">{{ $t("Submit") }}</vs-button>
      <vs-button
        type="border"
        color="danger"
        @click="isSidebarActiveLocal = false"
        >{{ $t("Cancel") }}</vs-button
      >
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },

  watch: {
    isSidebarActive(val) {
      if (!val) return;
      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
        let {
          Id,
          PaymentWayName,
          OperationNumber,
          OperationSide,
          OperationStatus,
        } = JSON.parse(JSON.stringify(this.data));
        this.Id = Id;
        this.PaymentWayName = PaymentWayName;
        this.OperationNumber = OperationNumber;
        this.OperationSide = OperationSide;
        this.OperationStatus = OperationStatus;
      }
    },
  },

  data() {
    return {
      Id: null,
      PaymentWayName: null,
      OperationNumber: null,
      OperationSide: null,
      OperationStatus: false,
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
    };
  },

  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit("closeSidebar");
          // this.$validator.reset()
          // this.initValues()
        }
      },
    },
    isFormValid() {
      return !this.errors.any() && this.Id;
    },
  },

  methods: {
    initValues() {
      if (this.data.Id) return;
      this.Id = null;
      this.PaymentWayName = null;
      this.OperationNumber = null;
      this.OperationSide = null;
      this.OperationStatus = false;
    },

    submitData() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          const obj = {
            Id: this.Id,
            PaymentWayName: this.PaymentWayName,
            OperationNumber: this.OperationNumber,
            OperationSide: this.OperationSide,
            OperationStatus: this.OperationStatus,
          };

          if (this.Id !== null && this.Id >= 0) {
            debugger;
            obj;
            this.$store
              .dispatch(
                "PaymentAndWithdrawalList/updatePaymentAndWithdrawalItem",
                obj
              )
              .then(() => {
                this.$store
                  .dispatch(
                    "PaymentAndWithdrawalList/fetchAllPaymentAndWithdrawalList"
                  )
                  .then(() => {
                    this.$vs.loading.close();
                  });
                window.showSuccess();
              })
              .catch((err) => {
                console.error(err);
              });
          } else {
            delete obj.Id;
            debugger;
            obj;
            this.$store
              .dispatch(
                "PaymentAndWithdrawalList/addPaymentAndWithdrawalItem",
                obj
              )
              .then(() => {
                this.$store
                  .dispatch(
                    "PaymentAndWithdrawalList/fetchAllPaymentAndWithdrawalList"
                  )
                  .then(() => {
                    this.$vs.loading.close();
                  });

                window.showSuccess();
              })
              .catch((err) => {
                console.error(err);
              });
          }

          this.$emit("closeSidebar");
          this.initValues();
        }
      });
    },
  },
  components: {
    VuePerfectScrollbar,
  },
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  height: calc(100% - 4.3rem);
}
</style>
